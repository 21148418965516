import React, {useEffect} from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Toolbar,
    IconButton,
    Typography,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Divider,
    Grid,
    TextField,
    InputAdornment,
    Button,
    FormLabel,
    FormControlLabel, Checkbox
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import { BayDbModel, BayPIN, Pin } from "../../models/bays";
import { updateFacility } from "../../redux/actions/facility.actions";
import { RootState } from "../../redux/store";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "@material-ui/pickers";
import axios from "axios";
import {Facility} from "../../models/facility";

interface LinkPinProps {
    pin: Pin;
    show: boolean;
    selectedFacility: Facility;
    handleSubmit: () => void;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        cellEntryBold: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
    })
);

export const LinkPin: React.FC<LinkPinProps> = ({
                                                    pin,
                                                    show,
                                                    selectedFacility,
                                                    handleSubmit,
                                                    handleClose
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [playerFirstName, setPlayerFirstName] = React.useState<string | undefined>(pin.playerName ?? undefined);
    const [playerLastName, setPlayerLastName] = React.useState<string | undefined>(pin.playerLastName ?? undefined);
    const [playerEmail, setPlayerEmail] = React.useState<string | undefined>(pin.playerEmail ?? undefined);

    const [isPlayerEmailValid, setIsPlayerEmailValid] = React.useState(false);
    const [isPlayerFirstNameValid, SetIsPlayerFirstNameValid] = React.useState(false);
    const [isPlayerLastNameValid, SetIsPlayerLastNameValid] = React.useState(false);

    const [showPlayerDetails, setShowPlayerDetails] = React.useState(pin.playerId !== null);
    const [isLinkPlayer, setIsLinkPlayer] = React.useState(pin.playerId !== null);

    const [welcomeMessage, setWelcomeMessage] = React.useState<string | undefined>(pin.welcomeMessage);

    const handleWelcomeMessageChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newMessage = (event.target.value as string).trim() === '' ? undefined : event.target.value as string;
        setWelcomeMessage(newMessage);
    };

    const handleCloseLinkPins = () => {
        // setShowPlayerDetails(false);

        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleSubmitLinkPin = () => {
        if (isLinkPlayer) {
            if(!isPlayerFirstNameValid || !isPlayerLastNameValid || !isPlayerEmailValid) {
                return
            }
        }

        // const bay = baySelected;
        // setBaySelected('');

        // setShowPlayerDetails(false);
        linkPinToBay(pin.id, baySelected, isLinkPlayer ?
            {
                firstName: playerFirstName,
                lastname: playerLastName,
                email: playerEmail
            } : undefined)
    }

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setBaySelected('');
        setSnackBarOpen(false);
    };

    const [baySelected, setBaySelected] = React.useState(pin.bay?.uuid ?? '');
    const handleBaySelectedChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setBaySelected(event.target.value as string);
    };

    const handleAddPlayerDetailsChange = () => {
        if(showPlayerDetails) {
            setShowPlayerDetails(false)
        } else {
            setShowPlayerDetails(true)
        }

        playerEmailChange(pin.playerEmail ?? undefined);
        playerFirstNameChange(pin.playerName ?? undefined);
        playerLastNameChange(pin.playerLastName ?? undefined);
    }

    const handlePlayerEmailChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerEmailChange(event.target.value as string)
    };

    const handlePlayerFirstNameChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerFirstNameChange(event.target.value as string)
    };

    const handlePlayerLastNameChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerLastNameChange(event.target.value as string)
    };

    function playerLastNameChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerLastName(undefined);
            SetIsPlayerLastNameValid(false);
        } else {
            setPlayerLastName(value);
            SetIsPlayerLastNameValid(true);
        }
    };

    function playerEmailChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerEmail(undefined);
            setIsPlayerEmailValid(false);
        } else {
            setPlayerEmail(value);
            setIsPlayerEmailValid(validateEmail(value));
        }
    }

    function playerFirstNameChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerFirstName(undefined);
            SetIsPlayerFirstNameValid(false);
        } else {
            setPlayerFirstName(value);
            SetIsPlayerFirstNameValid(true);
        }
    }

    const validateEmail = (email:string) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    };

    const handleIsLinkPinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLinkPlayer(event.target.checked);
        setShowPlayerDetails(!isLinkPlayer)
    };

    useEffect(() => {
        playerEmailChange(pin.playerEmail ?? undefined);
        playerFirstNameChange(pin.playerName ?? undefined);
        playerLastNameChange(pin.playerLastName ?? undefined);
    }, [pin]);

    const linkPinToBay = (pinId: string, bayId: string, player?: {
        firstName: string | undefined;
        lastname: string | undefined;
        email: string | undefined;
    } | undefined) => {

        // setLoading(true);

        let data = {
            player: player ? {
                firstName: player.firstName,
                lastname: player.lastname,
                email: player.email
            } : undefined,
            welcomeMessage: (welcomeMessage?.trim().length ?? 0) > 0 ? welcomeMessage : undefined
        };

        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/${selectedFacility.rangeId}/Pins/${pinId}/bays/${bayId}`,
                data
            )
            .then((res) => {
                handleSubmit()
            })
            .catch(reason => console.log(reason));
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={show}
                onClose={handleCloseLinkPins}
                aria-labelledby="form-dialog-title">

                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Link Booking
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseLinkPins}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        <Divider />
                        <div className="TextField-with-border-radius">
                            <Grid container justify="space-between">
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <Typography variant="h4">PIN: {pin?.pin}</Typography>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl} style={{ width: '50%' }}>
                                    <Select
                                        id="bays"
                                        label="Bay"
                                        color="primary"
                                        value={baySelected}
                                        onChange={handleBaySelectedChange}
                                        variant="outlined"
                                    >
                                        {bays.map((bay, index) => (
                                            <MenuItem key={bay.uuid} value={bay.uuid}>{bay.bayName}</MenuItem>
                                        ))}
                                    </Select>
                                    <InputLabel>Bay</InputLabel>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}
                                    fullWidth={true}>
                                    <TextField
                                        label="Time Slot Start"
                                        variant="outlined"
                                        color="primary"
                                        value={pin.timeSlotStart === undefined ? '-' : new Date(pin.timeSlotStart).toLocaleString()}
                                        disabled={true}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}
                                    fullWidth={true}>
                                    <TextField
                                        label="Time Slot End"
                                        variant="outlined"
                                        color="primary"
                                        value={pin.timeSlotEnd === undefined ? '-' : new Date(pin.timeSlotEnd).toLocaleString()}
                                        disabled={true}
                                    />
                                </FormControl>
                                <FormControl variant="outlined"
                                             hidden={baySelected === null}
                                             className={classes.formControl} fullWidth={true}>
                                    <FormLabel component="legend">Custom Welcome Message</FormLabel>
                                    <TextField
                                        label="Optional"
                                        variant="outlined"
                                        color="primary"
                                        value={welcomeMessage || ""}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={handleWelcomeMessageChange}
                                    />
                                </FormControl>
                                {/*<Grid container justify="flex-end">*/}
                                {/*    <FormControl variant="outlined" className={classes.formControl} style={{ width: '20%'}}>*/}
                                {/*        <Button variant="contained"*/}
                                {/*            color="primary"*/}
                                {/*            onClick={handleAddPlayerDetailsChange}*/}
                                {/*        >*/}
                                {/*            { pin.playerId === null ? 'Add Player' : 'Edit Player' }*/}
                                {/*        </Button>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="checkIsLinkPin"
                                                color="primary"
                                                disabled={pin.playerId !== null}
                                                onChange={handleIsLinkPinChange}
                                                checked={isLinkPlayer}
                                            />
                                        }
                                        label="Add player details"
                                        labelPlacement="end"
                                    />
                                </FormControl>
                                { showPlayerDetails && (
                                    <FormControl variant="outlined" className={classes.formControl}
                                        fullWidth={true}>
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            color="primary"
                                            disabled={pin.playerId !== null}
                                            value={playerEmail}
                                            onChange={handlePlayerEmailChange}
                                            error={!isPlayerEmailValid}
                                            helperText={!isPlayerEmailValid ? 'Email must be valid!' : ''}
                                        />
                                    </FormControl>
                                )}
                                { showPlayerDetails && (
                                    <FormControl variant="outlined" className={classes.formControl}
                                        fullWidth={true}>
                                        <TextField
                                            label="First name"
                                            variant="outlined"
                                            color="primary"
                                            value={playerFirstName}
                                            onChange={handlePlayerFirstNameChange}
                                            disabled={pin.playerId !== null}
                                            error={!isPlayerFirstNameValid}
                                            helperText={!isPlayerFirstNameValid ? 'First name must be valid!' : ''}
                                        />
                                    </FormControl>
                                )}
                                { showPlayerDetails && (
                                    <FormControl variant="outlined" className={classes.formControl}
                                        fullWidth={true}>
                                    <TextField
                                        label="Last name"
                                        variant="outlined"
                                        color="primary"
                                        disabled={pin.playerId !== null}
                                        value={playerLastName}
                                        onChange={handlePlayerLastNameChange}
                                        error={!isPlayerLastNameValid}
                                        helperText={!isPlayerLastNameValid ? 'Last name must be valid!' : ''}

                                    />
                                    </FormControl>
                                )}

                                <FormControl variant="outlined" className={classes.formControl}
                                        fullWidth={true}>
                                        <Button variant="contained"
                                            color="primary"
                                            style={{ minWidth: '400px' }}
                                            fullWidth={true}
                                            disabled={baySelected === '' || (isLinkPlayer && (!isPlayerEmailValid || !isPlayerFirstNameValid || !isPlayerLastNameValid))}
                                            onClick={handleSubmitLinkPin}
                                        >
                                            Link Booking
                                        </Button>
                                    </FormControl>
                            </Grid>
                        </div>
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
