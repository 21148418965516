import {
  BEGIN_LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH,
  FacilityAccess,
} from "./auth.actions.types";
import axios from "axios";

// Login User
export const login = (username: string, password: string) => (
  dispatch: Function
) => {
  dispatch({ type: BEGIN_LOGIN });
  axios
      .post(`https://dev-pin-api-inrangegolf-com.azurewebsites.net/v1/Auth/login`, {
    // .post(`${process.env.REACT_APP_BASE_URL}/Auth/login`, {
      email: username,
      password: password,
    })
    .then((res) => {
      // console.log(res.data);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const refresh = (facilities: FacilityAccess[]) => (
  dispatch: Function
) => {
  dispatch({ type: REFRESH, payload: facilities });
};

export const logout = () => (dispatch: Function) => {
  dispatch({ type: LOGOUT });
};
