import React from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Toolbar,
    IconButton,
    Typography,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Button, Tooltip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import { BayPIN, Pin } from "../../models/bays";
import LinkIcon from "@material-ui/icons/Link";
import {LinkPin} from "./LinkPin";
import {Facility} from "../../models/facility";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import {PlayerDialog} from "./AvailablePins";
import {UpdatePin} from "./UpdatePin";
import MessageIcon from "@material-ui/icons/Message";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";

interface FindPinProps {
    pins: Pin[]
    show: boolean;
    selectedFacility: Facility;
    allowGroupPinOption: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        cellEntryBold: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
    })
);

export const FindPin: React.FC<FindPinProps> = ({
                                                    pins,
                                                    show,
                                                    selectedFacility,
                                                    allowGroupPinOption,
                                                    handleClose
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [showLinkPin, setShowLinkPin] = React.useState(false);
    const [linkPin, setLinkPin] = React.useState<Pin | undefined>();

    const [showUpdatePin, setShowUpdatePin] = React.useState(false);
    const [updatePin, setUpdatePin] = React.useState<Pin | undefined>();

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [playerPin, setPlayerPin] = React.useState<Pin | undefined>();

    const handleCloseFindPins = () => {
        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const handleLinkPinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setShowLinkPin(false)
        setLinkPin(undefined)

        handleCloseFindPins()
    };

    const handleLinkPin = (pin: Pin) => {
        setLinkPin(pin);
        setShowLinkPin(true);
    };

    const handleUpdatePinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setShowUpdatePin(false)
        setUpdatePin(undefined)

        handleCloseFindPins()
    };

    const handleUpdatePin = (pin: Pin) => {
        setUpdatePin(pin);
        setShowUpdatePin(true);
    };

    const handlePlayerPinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setPlayerPin(undefined)
    };

    function getPlayerTooltip(pin: Pin) {

        if(pin.playerName) {
            return pin.playerName
        }

        return ""
    }

    function getPinStatus(pin: Pin) {

        if (pin.complete) {
            return "COMPLETE"
        }  else if(pin.groupBooking || pin.isStatic) {
            return "-"
        } else if(pin.startTime === undefined) {
            return "READY"
        } else if(pin.completeTime === undefined) {
            return "IN PROGRESS"
        }

        return "-"
    }

    function getPinStatusTooltip(pin: Pin) {

        if(pin.groupBooking) {
            return "Multi-Bay PIN"
        } else if(pin.isStatic) {
            return "Static PIN"
        } else if(pin.startTime === undefined) {
            return "Not started";
        } else if(pin.completeTime === undefined) {
            return "Started: " + new Date(pin.startTime).toLocaleString()
        } else if(pin.complete) {
            return "Completed: " + new Date(pin.completeTime).toLocaleString()
        }

        return "-"
    }

    const markPinAsCompleted = (pin: string, id: string) => {
        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/${selectedFacility.rangeId}/Pins/${pin}/complete/${id}`,
                {}
            )
            .then((res) => {
                handleCloseFindPins();
            })
            .catch(reason => console.log(reason));
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={show}
                onClose={handleCloseFindPins}
                aria-labelledby="form-dialog-title">

                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Find Booking
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseFindPins}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        {pins.length === 0 && (
                            <Typography variant="subtitle1" className={classes.noPins}>
                                No PINs found.
                            </Typography>
                        )}
                        {pins.length > 0 && (
                            <TableContainer>
                                <Table size="medium" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cellHeading}>PIN</TableCell>
                                            <TableCell className={classes.cellHeading}>Minutes</TableCell>
                                            <TableCell className={classes.cellHeading}>Mode</TableCell>
                                            <TableCell className={classes.cellHeading}>Static</TableCell>
                                            <TableCell className={classes.cellHeading}>Bay</TableCell>
                                            <TableCell className={classes.cellHeading}>Start Time</TableCell>
                                            <TableCell className={classes.cellHeading}>Timeslot Start</TableCell>
                                            <TableCell className={classes.cellHeading}>Timeslot End</TableCell>
                                            <TableCell className={classes.cellHeading}>Status</TableCell>
                                            <TableCell className={classes.cellHeading}>Meta</TableCell>
                                            <TableCell className={classes.cellHeading}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pins.map((row, index) => (
                                            <TableRow
                                                style={index % 2 ? { background: "#F3F3F3" } : { background: "white" }}
                                                key={row.id}>
                                                <TableCell className={classes.cellEntry}>{row.pin}</TableCell>
                                                <TableCell className={classes.cellEntry}>{row.minutes}</TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.playMode === "SP" ? 'Inrange' : (row.playMode === "MP" ? 'Inrange+' : row.playMode)
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>{
                                                    row.isStatic ? 'Yes' : '-'
                                                }</TableCell>
                                                <TableCell className={classes.cellEntryBold}>
                                                    <Tooltip title={getPlayerTooltip(row)} arrow placement="top">
                                                        <Typography>
                                                            { (!row.complete && row.startTime === undefined && row.timeSlotStart && row.timeSlotEnd) ? (
                                                                <Button
                                                                    variant="text"
                                                                    size="small"
                                                                    onClick={() => handleUpdatePin(row)}
                                                                >
                                                                    <b>{ row.groupBooking ? 'Multi' : row.bay?.bayName ?? row.bayNumbers }</b> <LinkIcon />
                                                                </Button>
                                                            ) : (
                                                                <b>{row.groupBooking ? 'Multi' : row.bay === undefined ? '-' : (row.bay?.bayName ?? row.bayNumbers)}</b>
                                                            )}
                                                        </Typography>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.startTime === undefined ? '-' : new Date(row.startTime).toLocaleString()
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.timeSlotStart === undefined ? '-' : new Date(row.timeSlotStart).toLocaleString()
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.timeSlotEnd === undefined ? '-' : new Date(row.timeSlotEnd).toLocaleString()
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    <Tooltip title={getPinStatusTooltip(row)} arrow placement="top">
                                                        <Typography>{ getPinStatus(row) }</Typography>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className={classes.cellEntryBold}>
                                                    <div style={{
                                                        flexDirection: 'row',
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        justifyContent: "start"
                                                    }}>
                                                        <Tooltip
                                                            title={row.welcomeMessage ?? '-'}
                                                            arrow placement="top">
                                                            <Typography hidden={!row.welcomeMessage}>
                                                                <MessageIcon/>
                                                            </Typography>
                                                        </Tooltip>
                                                        <Tooltip title={row.description ?? ''} arrow placement="top">
                                                            <Typography
                                                                hidden={(!row.description)}>
                                                                <DescriptionIcon/>
                                                            </Typography>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    <div style={{flexDirection:'row', display: 'flex', alignItems: 'flex-start', justifyContent: "start"}}>
                                                        <Button
                                                            hidden={row.playerId === null}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => setPlayerPin(row) }
                                                        >
                                                            <PersonIcon />
                                                        </Button>
                                                        <Button
                                                            hidden={row.complete || row.startTime != null}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => handleUpdatePin(row) }
                                                        >
                                                            <EditIcon />
                                                        </Button>
                                                        <Button
                                                            hidden={row.complete}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => markPinAsCompleted(row.pin, row.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </React.Fragment>
                    {linkPin == null ? "" : <LinkPin pin={linkPin!}
                                                     show={showLinkPin}
                                                     selectedFacility={selectedFacility}
                                                     handleSubmit={handleLinkPinClose}
                                                     handleClose={handleLinkPinClose}
                    >
                    </LinkPin>}
                    {updatePin == null ? "" : <UpdatePin pin={updatePin!}
                                                     show={showUpdatePin}
                                                     selectedFacility={selectedFacility}
                                                     handleSubmit={handleUpdatePinClose}
                                                     handleClose={handleUpdatePinClose}
                    >
                    </UpdatePin>}
                    <PlayerDialog
                        pin={playerPin}
                        open={playerPin !== undefined}
                        onClose={ handlePlayerPinClose }
                    />
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
