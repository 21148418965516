import React, { useEffect } from "react";
import { Typography, Grid, Divider, Select } from "@material-ui/core";
import FacilityInfo from "../components/FacilityInfo";
import {
  Button,
  useTheme,
  MuiThemeProvider,
  makeStyles,
  Theme,
  createStyles,
  fade,
  FormControl,
  TextField,
  InputLabel,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateFacility } from "../redux/actions/facility.actions";
import { SettingsModel } from "../models/settings";
import { Facility } from "../models/facility";
import { SettingListItem } from "../components/settings/SettingListItem";
import { SettingCreator } from "../components/settings/SettingCreator";

interface SettingsProps {
  facility: Facility;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const Settings: React.FC<SettingsProps> = ({ facility }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  const [settingData, setSettingData] = React.useState<SettingsModel[] | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [showNoData, setShowNoData] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [showSettingCreator, setShowSettingCreator] = React.useState(false);
  const [facilityDefaultPlayMode, setFacilityDefaultPlayMode] = React.useState(
    selectedFacility.defaultPlayMode
  );
  // const [facilityTimeZone, setFacilityTimeZone] = React.useState(
  //   selectedFacility.timeZone
  // );
  // const [facilityCurrency, setFacilityCurrency] = React.useState(
  //   selectedFacility.currency
  // );
  const [facilityPinBehaviour, setFacilityPinBehaviour] = React.useState(
      selectedFacility.behavior
  );
  const [facilityPinGroupOption, setFacilityPinGroupOption] = React.useState(
      selectedFacility.groupOption
  );
  const role = useSelector<RootState, string>(
      (selector) => selector.facility.role
  );
  // const [
  //   facilityPhoneNumberFormat,
  //   setFacilityPhoneNumberFormat,
  // ] = React.useState(facility.phoneNumberFormat);

  const [
    facilityPinLeadTimeMinutes,
    setFacilityPinLeadTimeMinutes,
  ] = React.useState(facility.leadTimeMinutes);

  useEffect(() => {
    setFacilityPinBehaviour(selectedFacility.behavior);
    setFacilityPinLeadTimeMinutes(selectedFacility.leadTimeMinutes);
    setFacilityPinGroupOption(selectedFacility.groupOption);
  }, [selectedFacility]);

  const getCurrentFacilitySettings = () => {
    setLoading(true);
    setShowNoData(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/FacilitySettings/${facility.rangeId}`,
        {
          headers: { "x-api-key": process.env.REACT_APP_APIKEY },
        }
      )
      .then((settings) => {
        setSettingData(settings.data);
        setLoading(false);
      });
  };

  const handleCloseCreator = () => setShowSettingCreator(false);
  const handleShowCreator = () => setShowSettingCreator(true);

  useEffect(() => {
    if (settingData && settingData.length > 0) setShowData(true);
    else setShowNoData(true);
  }, [settingData]);

  useEffect(() => {
    // This is not used and not working on the BE
    // getCurrentFacilitySettings();
  }, []);

  const handleDefaultPlayModeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFacilityDefaultPlayMode(event.target.value as string);
    dispatch(updateFacility(facility.rangeId, facility));
  };

  // const handleTimeZoneChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setFacilityTimeZone(event.target.value as string);
  //   dispatch(updateFacility(facility.uuid, facility));
  // };
  //
  // const handleCurrencyChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setFacilityCurrency(event.target.value as string);
  //   dispatch(updateFacility(facility.uuid, facility));
  // };
  //
  // const handlePhoneNumberFormatChange = (
  //   event: React.ChangeEvent<{ value: unknown }>
  // ) => {
  //   setFacilityPhoneNumberFormat(event.target.value as string);
  //   dispatch(updateFacility(facility.uuid, facility));
  // };

  const handlePinBehaviourChange = (
      event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const pinBehaviour = event.target.value as string;

    // Update the lead time property
    facility.behavior = pinBehaviour;

    setFacilityPinBehaviour(pinBehaviour);
    // Dispatch the updated facility
    dispatch(updateFacility(facility.rangeId, facility));
  };

  const handlePinGroupOptionChange = (
      event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const pinGroupOption = event.target.value as string;

    // Update the pin bay behavior
    facility.groupOption = pinGroupOption;

    setFacilityPinGroupOption(pinGroupOption);
    // Dispatch the updated facility
    dispatch(updateFacility(facility.rangeId, facility));
  };

  const handlePinLeadTimeMinutesChange = (
      event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newLeadTime = event.target.value as number;

    // Update the lead time property
    facility.leadTimeMinutes = newLeadTime;

    // Dispatch the updated facility
    setFacilityPinLeadTimeMinutes(newLeadTime);
    dispatch(updateFacility(facility.rangeId, facility));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container alignItems="center">
        <FacilityInfo />
        <Grid xs={12} lg={6} item>
          <Grid container justify="flex-end" alignItems="center">
            <Typography variant="h3">Settings</Typography>
          </Grid>
        </Grid>
      </Grid>
      <hr />
      <Grid>
        {/*<Typography variant="h5">{facility.name}</Typography>*/}
        {/*<Typography variant="body1">{facility.description}</Typography>*/}
        <Typography variant="h5">PIN Settings</Typography>
        {/* Hide the form controls, they are not currently used */}
        <FormControl style={{ display: 'none' }} variant="outlined" className={classes.formControl}>
          <InputLabel>Default Play Mode</InputLabel>
          <Select
            id="defaultPlayMode"
            label="Default Play Mode"
            color="primary"
            value={facilityDefaultPlayMode}
            onChange={handleDefaultPlayModeChange}
            variant="outlined"
          >
            <MenuItem value={"SP"}>Inrange</MenuItem>
            <MenuItem value={"MP"}>Inrange+</MenuItem>
          </Select>
        </FormControl>
        {/*<FormControl style={{ display: 'none' }} variant="outlined" className={classes.formControl}>*/}
        {/*  <InputLabel>Time Zone</InputLabel>*/}
        {/*  <Select*/}
        {/*    id="timeZone"*/}
        {/*    label="Time Zone"*/}
        {/*    type="text"*/}
        {/*    color="primary"*/}
        {/*    value={facilityTimeZone}*/}
        {/*    onChange={handleTimeZoneChange}*/}
        {/*    variant="outlined"*/}
        {/*  >*/}
        {/*    <MenuItem value={"BST"}>BST</MenuItem>*/}
        {/*    <MenuItem value={"SAST"}>SAST</MenuItem>*/}
        {/*    <MenuItem value={"EST"}>EST</MenuItem>*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}
        {/*<FormControl style={{ display: 'none' }} variant="outlined" className={classes.formControl}>*/}
        {/*  <InputLabel>Currency</InputLabel>*/}
        {/*  <Select*/}
        {/*    id="currency"*/}
        {/*    label="Currency"*/}
        {/*    type="text"*/}
        {/*    color="primary"*/}
        {/*    value={facilityCurrency}*/}
        {/*    onChange={handleCurrencyChange}*/}
        {/*    variant="outlined"*/}
        {/*  >*/}
        {/*    <MenuItem value={"USD"}>US Dollars</MenuItem>*/}
        {/*    <MenuItem value={"Euro"}>Euros</MenuItem>*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}
        {/*<FormControl style={{ display: 'none' }} variant="outlined" className={classes.formControl}>*/}
        {/*  <TextField*/}
        {/*    id="phoneNumberFormat"*/}
        {/*    label="Phone Number Format"*/}
        {/*    type="text"*/}
        {/*    color="primary"*/}
        {/*    value={facilityPhoneNumberFormat}*/}
        {/*    onChange={handlePhoneNumberFormatChange}*/}
        {/*    variant="outlined"*/}
        {/*  />*/}
        {/*</FormControl>*/}
        {/* Show these form controls */}
        {!loading && (
            <FormControl variant="outlined" className={classes.formControl} style={{ width: '22%' }}>
              <Select
                  id="pinLeadTimeMinutes"
                  label="PIN Lead Time Minutes"
                  color="primary"
                  value={facilityPinLeadTimeMinutes}
                  onChange={handlePinLeadTimeMinutesChange}
                  variant="outlined"
              >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={5}>5 Minutes</MenuItem>
                <MenuItem value={10}>10 Minutes</MenuItem>
                <MenuItem value={15}>15 Minutes</MenuItem>
                <MenuItem value={20}>20 Minutes</MenuItem>
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={45}>45 Minutes</MenuItem>
                <MenuItem value={60}>1 Hour</MenuItem>
                <MenuItem value={90}>1 Hour 30 Minutes</MenuItem>
                <MenuItem value={120}>2 Hours</MenuItem>
                <MenuItem value={150}>2 Hour 30 Minutes</MenuItem>
                <MenuItem value={180}>3 Hours</MenuItem>
              </Select>
              <InputLabel>PIN Lead Time Minutes</InputLabel>
            </FormControl>
        )}
        {!loading && (
          <FormControl variant="outlined" className={classes.formControl} style={{ width: '50%' }}>
            <Select
                id="pinBehaviour"
                label="PIN Behaviour"
                color="primary"
                value={facilityPinBehaviour}
                onChange={handlePinBehaviourChange}
                variant="outlined"
            >
              <MenuItem value={"TimeSlotStrict"}>Time-slot strict</MenuItem>
              {/*<MenuItem value={"TimeSlotLeadTimeDuration"}>Time-slot with lead time duration included</MenuItem>*/}
              <MenuItem value={"TimeSlotDuration"}>Time-slot with PIN duration</MenuItem>
              <MenuItem value={"Duration"}>No time-slot, only PIN duration</MenuItem>
            </Select>
            <InputLabel>PIN Behaviour</InputLabel>
          </FormControl>
        )}
        {!loading && (role === "ADMIN" || role === "MANAGER") && (
            <FormControl variant="outlined" className={classes.formControl} style={{ width: '22%' }}>
              <Select
                  id="pinGroupOption"
                  label="PIN Group Option"
                  color="primary"
                  value={facilityPinGroupOption}
                  onChange={handlePinGroupOptionChange}
                  variant="outlined"
              >
                <MenuItem value={"Allow"}>Allow</MenuItem>
                <MenuItem value={"Deny"}>Deny</MenuItem>
              </Select>
              <InputLabel>PIN Group Option</InputLabel>
            </FormControl>
        )}
      </Grid>
      <Divider variant="middle" />
      <Button variant="contained" color="primary" onClick={handleShowCreator} style={{ display: 'none' }} >
        Create Setting
      </Button>
      <SettingCreator
        show={showSettingCreator}
        handleClose={handleCloseCreator}
        facilityId={facility.rangeId}
      />
      <hr />

      {loading && (
        <div className="loading">
          <Typography color="primary">Loading Data...</Typography>
          <CircularProgress color="primary" />
        </div>
      )}
      {showData && settingData !== null && (
        <Paper elevation={3}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">Setting</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settingData.map((setting) => {
                  return <SettingListItem key={setting.id} setting={setting} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </MuiThemeProvider>
  );
};

export default Settings;
