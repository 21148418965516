import {
  FACILITY_CHANGE,
  FACILITY_LOADED,
  FacilityState,
} from "../actions/facility.actions";
import { Facility } from "../../models/facility";

const initialState: FacilityState = {
  selectedFacility: {
    rangeId: "",
    name: "",
    description: "",
    defaultPlayMode: "",
    // timeZone: "",
    // currency: "",
    // phoneNumberFormat: "",
    behavior: "",
    leadTimeMinutes: -1,
    groupOption: "",
  },
  role: "",
  facilities: [],
};

export default (state: FacilityState = initialState, action: any) => {
  switch (action.type) {
    case FACILITY_CHANGE: {
      localStorage.setItem("selectedfacility", action.payload.facility.uuid);
      localStorage.setItem("role", action.payload.role);
      return {
        ...state,
        selectedFacility: action.payload.facility,
        role: action.payload.role,
      };
    }
    case FACILITY_LOADED: {
      return {
        ...state,
        facilities: [
          ...state.facilities.filter((x) => x.rangeId !== action.payload.uuid),
          action.payload,
        ],
      };
    }

    default:
      return state;
  }
};
